export const PROBLEM_LEAN_CATEGORY = {
  SKILLS: 'Skills (MAN)',
  INPUT: 'Input (MATERIAL)',
  TOOLS: 'Tools (MACHINES)',
  PROCESS: 'Process (METHODS)',
};

export const PROBLEM_LEAN_CATEGORY_VALUES = {
  SKILLS: 'Skills',
  INPUT: 'Input',
  TOOLS: 'Tools',
  PROCESS: 'Process',
};
